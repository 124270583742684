import React from "react";
import "../../../public/css/Dashboard/Dashboard.css";

function ServiceRequestsCreateNew ()  {
  return (
    <div className="Dashboard">
      ServiceRequests_CreateNew
    </div>
  );
};
export default ServiceRequestsCreateNew;